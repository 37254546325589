import StakingPoolABI from "../abi/StakingPoolABI.json";

export const poolData = [
  {
    id: "pool1",
    label: "28 Days",
    apr: 15,
    ethPerencent: 10,
    lockupPeriod: 28,
    address: "0xd47dcfa5b1362647860b901C727A8428B814d450", // Replace with actual contract address
    tokenPoolAddress: "0x9E63631e657B19faebb88a93A897BefD428C02b3", // Replace with actual token pool address
    abi: StakingPoolABI,
    stakedFull: "...",
    total_stakedFull: "...",
  },
  {
    id: "pool2",
    label: "56 Days",
    apr: 30,
    ethPerencent: 30,
    lockupPeriod: 56,
    address: "0x033b6A802eBf1bf3B4F8a20D567121b2690fb022", // Replace with actual contract address
    tokenPoolAddress: "0x58CAC8c03D12d54EBa094B827E3af304F6232E8e", // Replace with actual token pool address
    abi: StakingPoolABI,
    stakedFull: "...",
    total_stakedFull: "...",
  },
  {
    id: "pool3",
    label: "84 Days",
    apr: 60,
    ethPerencent: 60,
    lockupPeriod: 84,
    address: "0x634DAEeCF243c844263D206e1DcF68F310e6BB19", // Replace with actual contract address
    tokenPoolAddress: "0x6876e661AE0F740C9132B7B8f26f7D245cFc62C1", // Replace with actual token pool address
    abi: StakingPoolABI,
    stakedFull: "...",
    total_stakedFull: "...",
  },
];

export const TOKEN_ADDRESS = "0x8e0EeF788350f40255D86DFE8D91ec0AD3a4547F";
export const TOKEN_TICKER = "$COR";
